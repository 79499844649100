import {
	request
} from "../utils/axios";

export default {
	// 通用获取全部 职位、级别、员工 数据列表（通用于页面下拉框数据）
	getCommonSelector(params = {}) {
		return request('get', `/employee/get_common_selector`, params);
	},

	// 员工 ==> 部门
	getDepartment(params = {}) {
		return request('get', `/department/get_department`, params);
	},

	deleteDepartment(params = {}) {
		return request('get', `/department/delete_department`, params);
	},

	updateDepartment(params = {}) {
		return request('post', `/department/update_department`, params);
	},

	addDepartment(params = {}) {
		return request('post', `/department/add_department`, params);
	},

	// 员工 ==> 岗位设置
	getLevelMaintList(params = {}) {
		return request('get', `/employee/get_Level_maint_list`, params);
	},

	addLevel(params = {}) {
		return request('post', `/employee/add_level`, params);
	},

	updateLevel(params = {}) {
		return request('post', `/employee/update_level`, params);
	},

	deleteLevel(params = {}) {
		return request('get', `/employee/delete_level`, params);
	},

	restoreLevel(params = {}) {
		return request('get', `/employee/restore_level`, params);
	},

	// 员工 ==> 员工
	getEmplMainList(params = {}) {
		return request('get', `/employee/get_empl_main_list`, params);
	},

	deleteEmpl(params = {}) {
		return request('get', `/employee/delete_empl`, params);
	},

	initSaveEmplData(params = {}) {
		return request('get', `/employee/init_save_empl_data`, params);
	},

	saveEmpl(params = {}) {
		return request('post', `/employee/save_empl`, params);
	},

	deleteEmplPricture(params = {}) {
		return request('get', `/employee/delete_empl_pricture`, params)
	},

	// 员工 ==> 员工副职
	getEmplViceList(params = {}) {
		return request('get', `/employee/get_empl_vice_list`, params)
	},

	initEmplVice(params = {}) {
		return request('get', `/employee/init_empl_vice`, params)
	},

	saveEmplVice(params = {}) {
		return request('post', `/employee/add_empl_vice`, params)
	},

	deleteEmplVice(params = {}) {
		return request('get', `/employee/delete_empl_vice`, params)
	},
}